<template>
  <b-row class="p-1 bg-white">
    <div class="text-right col-12 float-right" v-if="!loading.employees">
      <b-button variant="warning" class="mb-2" @click="openModal()">Nuevo usuario empleado</b-button>
    </div>
    <div class="col-12 px-0">
      <table-render
        :key="keyTableRender" 
        :rows="rows"
        :schema="schema"
        show-empty
        borderless= "borderless"
        class="mx-1"
        v-if="!loading.employees"
      >
        <template #receiveStatusChangeTickets="row">
          <feather-icon v-if="row.rowdata.receiveStatusChangeTickets" icon="CheckIcon" class="text-success" />
          <feather-icon v-if="!row.rowdata.receiveStatusChangeTickets" icon="XIcon" class="text-danger" />
        </template>
        <template #receiveLateickets="row">
          <feather-icon v-if="row.rowdata.receiveLateickets" icon="CheckIcon" class="text-success" />
          <feather-icon v-if="!row.rowdata.receiveLateickets" icon="XIcon" class="text-danger" />
        </template>
        <template #ticketStatusChangeReminder="row">
          <feather-icon v-if="row.rowdata.ticketStatusChangeReminder" icon="CheckIcon" class="text-success" />
          <feather-icon v-if="!row.rowdata.ticketStatusChangeReminder" icon="XIcon" class="text-danger" />
        </template>
        <template #ticketLateReminder="row">
          <feather-icon v-if="row.rowdata.ticketLateReminder" icon="CheckIcon" class="text-success" />
          <feather-icon v-if="!row.rowdata.ticketLateReminder" icon="XIcon" class="text-danger" />
        </template>
        <template #externalTicketReminder="row">
          <feather-icon v-if="row.rowdata.externalTicketReminder" icon="CheckIcon" class="text-success" />
          <feather-icon v-if="!row.rowdata.externalTicketReminder" icon="XIcon" class="text-danger" />
        </template>
        <template #actionsBtn="row">
          <b-button variant="flat-primary" v-b-tooltip.hover title="Editar" class="p-0" @click="openModal(row.rowdata.id)"><feather-icon icon="EditIcon" /></b-button>
          <b-button variant="flat-danger" v-b-tooltip.hover title="Eliminar" class="p-0" @click="confirmDelete(row.rowdata.id)"><feather-icon icon="TrashIcon" /></b-button>
        </template>
      </table-render>
      <b-skeleton height="40px" width="`calc(100vh - 2.5rem)`" class="mt-1 mb-1" v-if="loading.employees" />
      <div class="table-skeleton-tableQuoter" v-if="loading.employees">
        <b-skeleton-table :rows="15" :columns="10" :table-props="{ }"/>
      </div>
    </div>   
    <modal-employees 
      ref="ModalEmployees"
      :isEdit="isEdit"
      :keyModal="keyModal"
      :employees="employees"
      @getEmployees="getEmployees"
      :roles="roles"
    ></modal-employees>
  </b-row>
</template>
<script>
import { mapGetters } from 'vuex'
import ModalEmployees from './ModalEmployees.vue'
import BaseServices from '@/store/services/index'

export default {
  name: 'TableEmployedUsers',
  components: { ModalEmployees },
  data () {
    return {
      baseService: new BaseServices(this),
      keyTableRender: 0,
      schema: [],
      rows: [],
      company_id: this.$route.params?.id,
      isEdit: false,
      keyModal: 0,
      loading: {
        employees: false
      }
    }
  },
  computed: {
    ...mapGetters({
      employees: 'getEmployees',
      deleteEmployees: 'deleteEmployees',
      generalLoading: 'getLoading',
      roles: 'getRoles'
    })
  },
  watch: {
    employees() {
      this.setEmployees()
    },
    generalLoading: {
      handler () {
        this.loading.employees = !!this.generalLoading.getEmployees
      }
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.getEmployees()
      this.setSchema()
    },
    setSchema() {
      this.schema = [
        {label: 'Nombre', key: 'first_name'},
        {label: 'Apellido', key: 'last_name'},
        {label: 'Correo', key: 'email'},
        {label: 'Tipo de usuario', key: 'userType'},
        {label: 'Teléfono fijo', key: 'phone'},
        {label: 'Celular', key: 'mobile'},
        {label: 'Notificaciones de tickets', icon: 'HelpCircleIcon', text: 'Recibe un email por cada actualizacion de un ticket', key: 'receiveStatusChangeTickets', useSlot: true, class: 'text-center'},
        {label: 'Notificaciones de tickets por atraso', icon: 'HelpCircleIcon', text: 'Recibe un email por cada actualizacion de un ticket abierto por atraso', key: 'receiveLateickets', useSlot: true, class: 'text-center'},
        {label: 'Resumen tickets externos', icon: 'HelpCircleIcon', text: 'Recibe un reporte diario con los tickets abiertos por clientes externos', key: 'externalTicketReminder', useSlot: true, class: 'text-center'},
        {label: 'Resumen tickets', icon: 'HelpCircleIcon', text: 'Recibe un reporte diario con los tickets pendientes de respuesta', key: 'ticketStatusChangeReminder', useSlot: true, class: 'text-center'},
        {label: 'Resumen tickets por atraso', icon: 'HelpCircleIcon', text: 'Recibe un reporte diario con los tickets pendientes de respuesta abiertos por atraso', key: 'ticketLateReminder', useSlot: true, class: 'text-center'},
        {label: 'Acciones', key: 'actionsBtn', useSlot: true }
      ]
    },
    setEmployees() {
      if (this.employees.rows && this.roles) {
        const usesProfiles = !!this.employees.data.company?.marketplace && !!this.employees.data.company.marketplace_profiles?.uses_profiles
        const marketplaceUsesProfiles = this.employees.data.company.marketplace_profiles?.uses_profiles
        const rows = this.employees.rows.map(employee => {
          const userType = usesProfiles || marketplaceUsesProfiles ? 'profile' : 'rol'
          const type = (userType === 'profile') ? Object.values(this.employees.data?.profiles)?.find((userType) => userType.id === employee.profile_id) : Object.values(this.roles || [])?.find((userType) => userType.id === employee.role_id)
          this.getNotifications(employee)
          return {
            id: employee?.id,
            first_name: employee?.first_name || 'Sin info.',
            last_name: employee?.last_name || 'Sin info.',
            email: employee?.email || 'Sin info.',
            userType: type?.name || (userType === 'profile' ? 'No tiene un perfil asignado' : 'No tiene un rol asignado'),
            phone: employee?.phone || 'Sin info.',
            mobile: employee?.mobile || 'Sin info.',
            receiveStatusChangeTickets: !!employee?.receiveStatusChangeTickets,
            receiveLateickets: !!employee?.receiveLateickets,
            ticketStatusChangeReminder: !!employee?.ticketStatusChangeReminder,
            ticketLateReminder: !!employee?.ticketLateReminder,
            externalTicketReminder: !!employee?.externalTicketReminder,
            updatedAt: employee?.updated_at,
            activated: employee?.activated
          }
        })
        this.rows = rows.sort((a, b) => (new Date(b.updatedAt) || 0) - (new Date(a.updatedAt) || 0))
        this.keyTableRender++
      } else {
        setTimeout(() => {
          this.setEmployees()
        }, 100)
      }
    },
    getNotifications(employee) {
      if (employee.special_functions) {
        const specialFunctions = JSON.parse(employee.special_functions)
        employee.receiveStatusChangeTickets = specialFunctions.includes('receive-status-change-tickets')
        employee.receiveLateickets = specialFunctions.includes('receive-late-tickets')
        employee.ticketStatusChangeReminder = specialFunctions.includes('ticket_status_change_reminder')
        employee.ticketLateReminder = specialFunctions.includes('ticket_late_reminder')
        employee.externalTicketReminder = specialFunctions.includes('external_ticket_reminder')
      }
    },
    getEmployees() {
      this.$store.dispatch('fetchService', { name: 'getEmployees', params: {company_id: this.company_id} })
      this.$store.dispatch('fetchService', { name: 'getRoles', params: {company_id: this.company_id} })
    },
    openModal(id) {
      this.keyModal++
      this.isEdit = id
      this.$bvModal.show('ModalEmployees')
    },
    confirmDelete(id) {
      this.$yesno(this.$t('msg-pregunta-eliminar-elemento'), () => this.deleteEmployee(id))
    },
    deleteEmployee(id) {
      this.baseService.callService('deleteEmployees', {}, {employee_id: id})
        .then(() => {
          this.$bvToast.toast('Felicitaciones', {
            title: 'Usuario eliminado con éxito',
            variant: 'success',
            autoHideDelay: 2000
          })
          this.getEmployees()
        })
        .catch(err => {
          this.$bvToast.toast(`${err}`, {
            title: 'Ocurrió un problema al eliminar este usuario.',
            variant: 'danger',
            noAutoHide: true
          })
        })
    }
  }
}
</script>
