<template>
  <b-modal 
    id="ModalEmployees" 
    :title="$t(isEdit ? 'Editar usuario empleado' : 'Nuevo usuario empleado')" 
    no-close-on-backdrop 
    centered 
    modal-class="dialog-800"
    @close="close"
    hide-footer
  >
    <form-render 
      :form.sync="formEmployees"
      :fields="fields"
      containerButtonsClass="col-sm-12"
      :key="keyFormRenderEmployees"
      ref="formRenderEmployees"
      @send="send"
    >
      <template #notifications>
        <div>
          <span>Notificaciones</span>
          <feather-icon icon="MailIcon" size="14" class="notification-mail-icon"></feather-icon>
        </div>
      </template>
    </form-render>
    <div class="footer-modal-coverage">
      <b-button class="float-right mt-1 mb-1" variant="warning" @click="ok" :disabled="loading.createEmployees">
        <span v-if="!loading.createEmployees" class="mb-1">{{ isEdit ? 'Editar' : 'Agregar' }}</span>
        <b-spinner v-if="loading.createEmployees" small></b-spinner>
      </b-button>
    </div>
  </b-modal>
</template>
 
<script>
import { mapGetters } from 'vuex'
import BaseServices from '@/store/services/index'

export default {
  name: 'modal-employees',
  props: ['isEdit', 'keyModal', 'getEmployees', 'employees', 'roles'],
  data() {
    return {
      baseService: new BaseServices(this),
      company_id: this.$route.params?.id,
      fields: [],
      formEmployees: {},
      keyFormRenderEmployees: 0,
      loading: {
        createEmployees: false
      }
    }
  },
  computed: {
    ...mapGetters({
      deleteEmployees: 'deleteEmployees'
    })
  },
  watch: {
    countries() {
    },
    mySession() {
    },
    keyModal() {
      if (this.isEdit) {
        this.setForm()
      } else {
        this.setInitialData()
      }
    }
  },
  methods: {
    setInitialData() {
      this.fields = [
        { fieldType: 'FieldInput', useLabel: true, name: 'first_name', label: 'Nombre', validation: 'required', containerClass: 'col-6 col-md-6 container-info' },
        { fieldType: 'FieldInput', useLabel: true, name: 'last_name', label: 'Apellido', validation: 'required', containerClass: 'col-6 col-md-6 container-info' },
        { fieldType: 'FieldInput', useLabel: true, name: 'email', label: 'Correo', validation: 'required', containerClass: 'col-6 col-md-6 container-info' },
        { fieldType: 'FieldInput', useLabel: true, name: 'position', label: 'Cargo', containerClass: 'col-6 col-md-6 container-info' },
        { fieldType: 'FieldInput', useLabel: true, name: 'phone', label: 'Teléfono de contacto', containerClass: 'col-6 col-md-6 container-info' },
        { fieldType: 'FieldInput', useLabel: true, name: 'mobile', label: 'Celular', containerClass: 'col-6 col-md-6 container-info' },
        {name: 'notifications', useSlot: true, containerClass: 'col-12 col-md-12 container-info'},
        { fieldType: 'FieldCheckbox', useLabel: true, name: 'receive_status_change_tickets', containerClass: 'col-12 col-md-12 container-info', options: [{id: 1, text: 'Notificaciones de tickets por cambio de estado'}] },
        { fieldType: 'FieldCheckbox', useLabel: true, name: 'receive_late_tickets', containerClass: 'col-12 col-md-12 container-info', options: [{id: 1, text: 'Notificaciones de tickets por atraso'}] },
        { fieldType: 'FieldCheckbox', useLabel: true, name: 'ticket_status_change_reminder', containerClass: 'col-12 col-md-12 container-info', options: [{id: 1, text: 'Resumen tickets por cambio de estado'}] },
        { fieldType: 'FieldCheckbox', useLabel: true, name: 'ticket_late_reminder', containerClass: 'col-12 col-md-12 container-info', options: [{id: 1, text: 'Resumen tickets por atraso'}] },
        { fieldType: 'FieldCheckbox', useLabel: true, name: 'external_ticket_reminder', containerClass: 'col-12 col-md-12 container-info', options: [{id: 1, text: 'Resumen tickets externos'}] },
        { fieldType: 'FieldSelect', useLabel: true, useSkeleton: true, useSlot: true, name: 'roles', label: 'Rol', containerClass: 'col-12 col-md-12 fieldSelect', validation: 'required', options: [] }
      ]
      if (this.isEdit) this.fields.push({ fieldType: 'FieldRadio', useLabel: true, name: 'status', align: 'h', label: 'Estado', containerClass: 'col-12 col-md-12 container-info pt-1', options: [{id: 1, text: 'Activo'}, {id: 2, text: 'Inactivo'}] })
      const useProfiles = this.employees.data.company.marketplace && this.employees.data.company.company_profiles?.uses_profiles
      const marketplaceUsesProfiles = this.employees.data.company.marketplace_profiles?.uses_profiles
      const indexRoles = this.fields.findIndex(field => field.name === 'roles')
      if (useProfiles && ['admin', 'superadmin', 'marketplace'].includes(this.$session.get('cas_user').role)) {
        this.fields.splice(indexRoles, 1, { fieldType: 'FieldSelect', useLabel: true, useSkeleton: true, useSlot: true, name: 'profiles', label: 'Perfil', containerClass: 'col-12 col-md-12 fieldSelect container-info', validation: 'required', options: [], change: this.changeProfiles, disabled: !!this.isEdit })
        this.setProfiles()
        this.changeProfiles('mkpPudos', this.formEmployees.profiles)
      } else if (!useProfiles && !marketplaceUsesProfiles && ['admin', 'superadmin'].includes(this.$session.get('cas_user').role)) {
        this.setRoles()
      } else {
        const indexRoles = this.fields.findIndex(field => field.name === 'roles')
        this.fields.splice(indexRoles, 1)
      }
    },
    setRoles() {
      // Object.values(this.companyInfo?.rows?.roles).forEach(role => {
      const filterRoles = this.roles.filter(rol => !['pudo'].includes(rol.code))
      Object.values(filterRoles || []).forEach(role => {
        this.fields.find(field => field.name === 'roles').options.push({id: role.id, text: role.name})
      })
      this.setSkeletonField('roles', false)
    },
    setProfiles() {
      // Object.values(this.companyInfo?.rows?.profiles).forEach(profile => {
      Object.values(this.employees.data?.profiles || []).forEach(profile => {
        this.fields.find(field => field.name === 'profiles').options.push({id: profile.id, text: profile.name})
      })
      this.setSkeletonField('profiles', false)
    },
    setPudos() {
      // Object.values(this.companyInfo?.rows?.mkp_pudos).forEach(profile => {
      Object.values(this.employees.data?.mkp_pudos || []).forEach(profile => {
        this.fields.find(field => field.name === 'mkpPudos').options.push({id: profile.id, text: profile.name})
      })
      this.setSkeletonField('mkpPudos', false)
    },
    setSkeletonField(name, bool) {
      const index = this.fields.findIndex(field => field.name === name)
      this.fields[index].useSlot = bool
      this.fields[index].useSkeleton = bool
      this.fields[index].useLabel = bool
    },
    setForm() {
      const employee = this.employees.rows.find(employee => employee.id === this.isEdit)
      const specialFunctions = employee.special_functions ? JSON.parse(employee.special_functions) : []
      // const role = Object.values(this.companyInfo.rows.roles)?.find((role) => role.id === employee.role_id)
      // const profile = Object.values(this.companyInfo.rows.profiles)?.find((profile) => profile.id === employee.profile_id)
      // const mkpPudo = Object.values(this.companyInfo.rows.mkp_pudos)?.find((mkp_pudo) => mkp_pudo.id === employee.pudo_id)
      const role = Object.values(this.roles || [])?.find((role) => role.id === employee.role_id)
      const profile = Object.values(this.employees.data?.profiles || [])?.find((profile) => profile.id === employee.profile_id)
      const mkpPudo = Object.values(this.employees.data?.mkp_pudos || [])?.find((mkp_pudo) => mkp_pudo.id === employee.pudo_id)
      const employeeStatus = employee.activated === 1 ? {id: 1, text: 'Activo'} : {id: 2, text: 'Inactivo'}
      this.formEmployees = {
        first_name: employee.first_name,
        last_name: employee.last_name,
        email: employee.email,
        position: employee.position,
        phone: employee.phone,
        mobile: employee.mobile,
        receive_status_change_tickets: specialFunctions.includes('receive-status-change-tickets'),
        receive_late_tickets: specialFunctions.includes('receive-late-tickets'),
        ticket_status_change_reminder: specialFunctions.includes('ticket_status_change_reminder'),
        ticket_late_reminder: specialFunctions.includes('ticket_late_reminder'),
        external_ticket_reminder: specialFunctions.includes('external_ticket_reminder'),
        roles: {id: role?.id, text: role?.name},
        profiles: {id: profile?.id, text: profile?.name},
        mkpPudos: {id: mkpPudo?.id, text: mkpPudo?.name},
        status: employeeStatus
      }
      this.setInitialData()
    },
    changeProfiles(name, value) {
      const indexPudo = this.fields.findIndex(field => field.name === 'mkpPudos')
      const indexProfile = this.fields.findIndex(field => field.name === 'profiles')
      if (value?.id === 2 && indexPudo === -1) {
        this.fields.splice(indexProfile + 1, 0, { fieldType: 'FieldSelect', useLabel: true, useSkeleton: true, useSlot: true, name: 'mkpPudos', label: 'Selecciona la tienda del usuario', containerClass: 'col-12 col-md-12 fieldSelect container-info', validation: 'required', options: [] })
        this.setPudos()
      } else if (indexPudo !== -1) {
        this.fields.splice(indexPudo, 1)
      }
    },
    ok(e) {
      e.preventDefault()
      this.$refs.formRenderEmployees.checkForm()
    },
    send() {
      const queryParams = {
        first_name: this.formEmployees.first_name,
        last_name: this.formEmployees.last_name,
        email: this.formEmployees.email,
        special_functions: []
      }
      if (this.formEmployees.receive_status_change_tickets) queryParams.special_functions.push('receive-status-change-tickets')
      if (this.formEmployees.receive_late_tickets) queryParams.special_functions.push('receive-late-tickets')
      if (this.formEmployees.ticket_status_change_reminder) queryParams.special_functions.push('ticket_status_change_reminder')
      if (this.formEmployees.ticket_late_reminder) queryParams.special_functions.push('ticket_late_reminder')
      if (this.formEmployees.external_ticket_reminder) queryParams.special_functions.push('external_ticket_reminder')
      if (!!this.isEdit) queryParams.activated = this.formEmployees.status?.id === 1 ? 1 : 0
      if (this.formEmployees.roles?.id) queryParams.role_id = this.formEmployees.roles?.id
      if (this.formEmployees.profiles?.id) queryParams.profile_id = this.formEmployees.profiles?.id
      if (this.formEmployees.position) queryParams.position = this.formEmployees.position
      if (this.formEmployees.phone) queryParams.phone = this.formEmployees.phone
      if (this.formEmployees.mkpPudos?.id) queryParams.pudo_id = this.formEmployees.mkpPudos.id
      if (this.formEmployees.mobile) queryParams.mobile = this.formEmployees.mobile
      this.createEmployee(queryParams)
    },
    createEmployee(queryParams) {
      const name = this.isEdit ? 'updateEmployees' : 'createEmployees'
      const success = this.isEdit ? 'actualizado' : 'creado'
      const errors = this.isEdit ? 'actualizar' : 'crear'
      const params = this.isEdit ? {employee_id: this.isEdit} : {company_id: this.company_id}
      this.loading.createEmployees = true
      this.baseService.callService(name, queryParams, params)
        .then(() => {
          this.$bvToast.toast('Felicitaciones', {
            title: `Usuario ${success} con éxito`,
            variant: 'success',
            autoHideDelay: 2000
          })
          this.close()
          this.$emit('getEmployees')
          this.$emit('employees')
        })
        .catch(err => {
          this.$bvToast.toast(`${err}`, {
            title: `Ocurrió un problema al ${errors} este usuario.`,
            variant: 'danger',
            noAutoHide: true
          })
        })
        .finally(() => {
          this.loading.createEmployees = false
        })
    },
    close() {
      this.$bvModal.hide('ModalEmployees')
      this.cleanForm()
    },
    cleanForm(omit = null) {
      Object.keys(this.formEmployees).forEach(key =>  {
        if (key !== omit) delete this.formEmployees[key]
      })
    }
  }
}
</script>
<style scoped>
.notification-mail-icon {
  margin-bottom: 4px;
  margin-left: 4px;
}
</style>
